import i18next from 'i18next'
import React from 'react'
import Chart from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import KpiWrapper from "../../../components/kpiWrapper";
import { getTripsKPI } from '../../../helpers/kpiHelper'
import PropTypes from 'prop-types'

export default function TotalTripsChart({ isLoading }) {
    const { t } = useTranslation()

    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer)
    const { total, data } = getTripsKPI(confirmTrips, type, range, dateStart, dateEnd)

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!total || total == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.totalTripsChart.title")}
            displayNoData={displayNoData}
            total={`${String(total || 0).toLocaleString()}`}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ColumnChart"
                graphID={'chart_total_trips'}
                data={data}
                chartLanguage={i18next.language}
                options={{
                    width: '100%',
                    vAxis: { title: t("commandBox.trips.timeSlotChart.vAxis") },
                    seriesType: "bars",
                    isStacked: true,
                }}
            />
        </KpiWrapper >
    )
}

TotalTripsChart.propTypes = {
    isLoading: PropTypes.bool
}