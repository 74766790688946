import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getTripsTimeSlots } from "../../../helpers/kpiHelper";
import PropTypes from 'prop-types'

export default function TimeSlotsChart({ isLoading }) {
    const { t } = useTranslation()
    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const data = getTripsTimeSlots(confirmTrips, type)

    const chartOptions = {
        vAxis: { title: t("commandBox.trips.timeSlotChart.vAxis") },
        seriesType: "bars",
        isStacked: true,
    };

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && data && data[0].length <= 1

    return (
        <KpiWrapper
            title={t("commandBox.trips.timeSlotChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_time_slots'}
                data={data}
                options={chartOptions}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}

TimeSlotsChart.propTypes = {
    isLoading: PropTypes.bool
}