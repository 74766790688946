import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import KpiWrapper from "../../../components/kpiWrapper";
import { getTotalJourneysKpi } from "../../../helpers/kpiHelper";

export default function TotalJourneysChart({ isLoading }) {
    const { t } = useTranslation()

    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)
    const { type, range, dateStart, dateEnd, filterBy } = useSelector(state => state.kpiFiltersReducer)

    const isFilterByOne = filterBy?.length === 1

    const { data, total } = useMemo(() => {
        return getTotalJourneysKpi(journeys, type, range, dateStart, dateEnd, isFilterByOne)
    }, [journeys, type, range, dateStart, dateEnd, isFilterByOne])

    isLoading = isLoading || isJourneysLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.journeys.totalJourneys.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={total}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_total_journeys'}
                data={data}
                chartLanguage={i18next.language}
                options={{
                    width: '100%',
                    vAxis: { title: t("commandBox.trips.timeSlotChart.vAxis") },
                    seriesType: "bars",
                    isStacked: true,
                }}
            />
        </KpiWrapper>
    )
}

TotalJourneysChart.propTypes = {
    isLoading: PropTypes.bool
};   