import React from "react";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { getAvgDistanceKPI } from "../../../helpers/kpiHelper";
import PropTypes from 'prop-types'
export default function AvgDistanceChart({ isLoading }) {
    const { t } = useTranslation();
    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer)
    const { data, total } = getAvgDistanceKPI(confirmTrips, type, range, dateStart, dateEnd)

    isLoading = (isLoading || isConfirmTripsLoading)
    const displayNoData = !isLoading && data?.length === 0

    return (
        <KpiWrapper
            title={t("commandBox.trips.avgDistance.title")}
            isLoading={isLoading}
            displayNoData={displayNoData}
            total={`${total.toFixed(2)} km`}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_avg_distance'}
                data={data}
                chartLanguage={i18next.language}
                options={{
                    seriesType: "bars",
                    isStacked: true,
                }}
            />
        </KpiWrapper>
    )

}

AvgDistanceChart.propTypes = {
    isLoading: PropTypes.bool
}