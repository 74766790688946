import i18next from "i18next"
import {
    getAlertTable,
    getAvgDistanceKPI,
    getDriverTable,
    getEconomicFeeKPI,
    getJourneyKms,
    getJourneyKmsKPI,
    getTotalJourneysKpi,
    getJourneyPassengers,
    getJourneyTimeKPI,
    getOriginDestinationChart,
    getPunctualityKPI,
    getReservationTable,
    getReservationTimeSlotsChart,
    getTimeServiceByDriverChart,
    getTotalTripsByUserChart,
    getTripsKPI,
    getTripsPerUsers,
    getTripStateTable,
    getTripsTimeSlots,
    getUsersGroupBy,
    getVehicleCapacityKPI,
    getWheelJourneyDriversTable,
    getJourneysTripsKPI
} from "./kpiHelper"
import moment from "moment"
import { CSV_SEPARATOR } from "../../../constants/generics"


const buildPunctualityChart = (confirmTrips, type) => {
    if (!confirmTrips?.length) return ''

    let csvString = ''
    const { data } = getPunctualityKPI(confirmTrips, type)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString

}

const buildAvgDistanceChart = (confirmTrips, type, range, dateStart, dateEnd) => {
    if (!confirmTrips?.length) return ''

    let csvString = ''

    const { data } = getAvgDistanceKPI(confirmTrips, type, range, dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTripStateTable = reservations => {
    if (!Object.keys(reservations).length) return ''

    let csvString = ''

    const { rows, total } = getTripStateTable({ reservations })
    csvString += `${i18next.t('commandBox.trips.tripStateTable.subtitle') + '' + i18next.t('commandBox.trips.tripStateTable.title')
        }${CSV_SEPARATOR}${i18next.t('commandBox.summary.kpiWrapper.amount')}\n`
    rows.forEach(row => (csvString += `${row.name}${CSV_SEPARATOR}${row.unit}\n`))
    csvString += `${i18next.t('commandBox.summary.kpiWrapper.total')}${CSV_SEPARATOR}${total}\n`
    return csvString
}

const buildTotalTripsChart = (confirmTrips, type, range, dateStart, dateEnd) => {
    if (!confirmTrips?.length) return ''

    let csvString = ''

    const { data } = getTripsKPI(confirmTrips, type, range, dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildAlertTable = alerts => {
    if (!alerts) return ''

    let csvString = ''

    const { rows, total } = getAlertTable({ alerts })
    csvString += `${i18next.t('commandBox.summary.alertTable.subtitle') +
        i18next.t('commandBox.summary.alertTable.title')
        }${CSV_SEPARATOR}${i18next.t('commandBox.summary.kpiWrapper.amount')}\n`
    rows.forEach(row => (csvString += `${row.name}${CSV_SEPARATOR}${row.unit}\n`))

    csvString += `${i18next.t('commandBox.summary.kpiWrapper.total')}${CSV_SEPARATOR}${total}\n`
    return csvString
}

const buildTotalCustomersChart = (customers, type, range, dateStart, dateEnd) => {
    if (!customers) return ''

    let csvString = ''
    const { data } = getUsersGroupBy(customers, type, range, dateStart, dateEnd);
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTripsPerUser = (trips, customers, type, range, dateStart, dateEnd) => {
    let csvString = ''
    const { data } = getTripsPerUsers(trips, customers, type, range, dateStart, dateEnd);
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildReservationTable = reservations => {
    if (!reservations) return ''

    let csvString = ''

    const { rows, totalBO, totalUserApp, totalUserWeb, totalOther } = getReservationTable({ reservations })
    csvString += `${i18next.t('commandBox.summary.reservationTable.header.cell1')}${CSV_SEPARATOR}${i18next.t(
        'commandBox.summary.reservationTable.header.cell2')}${CSV_SEPARATOR}${i18next.t('commandBox.summary.reservationTable.header.cell3')}${CSV_SEPARATOR}${i18next.t(
            'commandBox.summary.reservationTable.header.cell4')}${CSV_SEPARATOR}${i18next.t('commandBox.summary.reservationTable.header.cell5')}${CSV_SEPARATOR}${i18next.t('commandBox.summary.reservationTable.header.cell6')}\n`

    rows.forEach(
        row =>
            (csvString += `${row.name}${CSV_SEPARATOR}${row.byBackOffice}${CSV_SEPARATOR}${row.byUserApp}${CSV_SEPARATOR}${row.byUserWeb}${CSV_SEPARATOR}${row.other}${CSV_SEPARATOR}${row.total}\n`),
    )

    const total = totalBO + totalUserApp + totalUserWeb + totalOther
    csvString += `${i18next.t('commandBox.summary.kpiWrapper.total')}${CSV_SEPARATOR}${totalBO}${CSV_SEPARATOR}${totalUserApp}${CSV_SEPARATOR}${totalUserWeb}${CSV_SEPARATOR}${totalOther}${CSV_SEPARATOR}${total}\n`
    return csvString
}

const buildTimeSlotChart = (confirmTrips, type) => {
    if (!confirmTrips?.length) return ''

    let csvString = ''

    const data = getTripsTimeSlots(confirmTrips, type)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTripsJourneysChart = (journeys, type) => {
    if (!journeys?.length) return ''

    let csvString = ''
    const { data } = getJourneysTripsKPI(journeys, type)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildNumberPassengersChart = (journeys, type, range, dateStart, dateEnd) => {
    if (!journeys?.length) return ''

    let csvString = ''
    const { data } = getJourneyPassengers(journeys, type, range, dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTotalJourneysChart = (journeys, type, range, dateStart, dateEnd) => {
    if (!journeys?.length) return ''

    let csvString = ''

    const { data } = getTotalJourneysKpi(journeys, type, range, dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildJourneysDistanceChart = (journeys, type, range, dateStart, dateEnd) => {
    if (!journeys?.length) return ''

    let csvString = ''
    const { data } = getJourneyKmsKPI(journeys, type, range, dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildJourneysDurationChart = (journeys, type, range, dateStart, dateEnd) => {
    if (!journeys?.length) return ''

    let csvString = ''
    const { data } = getJourneyTimeKPI(journeys, type, range, dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildReservationTimeSlotsChat = (tripBooking, type) => {
    if (!tripBooking?.length) return ''

    let csvString = ''
    const { data } = getReservationTimeSlotsChart({ tripBooking, type })
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTotalTripByUserChart = (trips, type) => {
    if (!trips?.length) return ''
    let csvString = ''
    const { header, body } = getTotalTripsByUserChart({ trips, type })

    csvString += `${header[0].name}${CSV_SEPARATOR}`

    header.slice(1).forEach((elem, i) => {
        csvString += `${elem.title}${i + 1 !== header.slice(1).length ? CSV_SEPARATOR : '\r\n'}`
    })

    body.forEach(elem => {
        const values = Object.values(elem)
        values.forEach((val, i) => {
            csvString += `${val}${i + 1 !== values.length ? CSV_SEPARATOR : '\r\n'}`
        })
    })


    return csvString
}

const buildTotalDriversChart = (drivers, type, range, dateStart, dateEnd) => {
    if (!drivers) return ''

    let csvString = ''
    const { data } = getUsersGroupBy(drivers, type, range, dateStart, dateEnd);
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTimePerDriverChart = (driverCalendar, weekDays) => {
    if (!driverCalendar?.length) return ''
    let csvString = ''
    const data = getTimeServiceByDriverChart({ driverCalendar, weekDays })

    csvString += `${i18next.t('commandBox.days')}${CSV_SEPARATOR}${i18next.t('commandBox.user')}${CSV_SEPARATOR}${i18next.t('commandBox.startingTime')}${CSV_SEPARATOR}${i18next.t('commandBox.endingTime')}\r\n`
    data.splice(1).forEach((elem) => {
        const weekDays = elem[0].replaceAll(',', '')
        const name = String(elem[1])
        const startingTime = moment(elem[2]).format('HH:mm')
        const endingTime = moment(elem[3]).format('HH:mm')

        csvString += `${weekDays}${CSV_SEPARATOR}${name}${CSV_SEPARATOR}${startingTime}${CSV_SEPARATOR}${endingTime}\r\n`
    })
    return csvString
}

const buildDriverDistanceChart = (journeys) => {
    if (!journeys?.length) return ''

    let csvString = ''
    const { data } = getJourneyKms(journeys, "driverName")
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildWheelJourneyDriversTable = (driverRequest) => {
    if (!driverRequest?.length) return ''
    let csvString = ''

    const data = getWheelJourneyDriversTable({ driverRequest })

    csvString += `${i18next.t("commandBox.drivers.wheelJourneyDriversTable.driver")}${CSV_SEPARATOR}${i18next.t("commandBox.drivers.wheelJourneyDriversTable.acceptedServices")}${CSV_SEPARATOR}${i18next.t("commandBox.drivers.wheelJourneyDriversTable.rejectedServices")}\r\n`

    Object.entries(data).forEach(([key, value]) => {
        csvString += `${key}${CSV_SEPARATOR}${value.acceptedServices}${CSV_SEPARATOR}${value.rejectedServices}\r\n`
    })

    return csvString
}

const buildDriversJourneyTable = (journeys, confirmTrips) => {
    if (!journeys?.length || !confirmTrips?.length) return ''
    let csvString = ''

    let data = getDriverTable({ journeys, confirmTrips })
    csvString += `${i18next.t("commandBox.drivers.driverTable.driver")}${CSV_SEPARATOR}${i18next.t("commandBox.drivers.driverTable.journeys")}${CSV_SEPARATOR}${i18next.t("commandBox.drivers.driverTable.lateTrips")}${CSV_SEPARATOR}${i18next.t("commandBox.drivers.driverTable.price")}\r\n`

    Object.entries(data).forEach(([key, value]) => {
        const punctualityPercentage = `${value.unpunctuality > 0 ? Math.round(value.unpunctuality / value.totalTrips * 100) : 0}`
        const price = `${value.price.toFixed(2)}`
        csvString += `${key}${CSV_SEPARATOR}${value.journeys}${CSV_SEPARATOR}${punctualityPercentage}${CSV_SEPARATOR}${price}\r\n`
    })

    return csvString
}

const buildVehicleCapacityChart = (vehicleGeneralData) => {
    if (!vehicleGeneralData?.length) return ''
    let csvString = ''
    const data = getVehicleCapacityKPI(vehicleGeneralData)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildVehicleDistanceChart = (journeys) => {
    if (!journeys?.length) return ''
    let csvString = ''
    const { data } = getJourneyKms(journeys, "vehicleDisplayName")
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildUserPayFeeChart = (confirmTrips, type, range, dateStart, dateEnd) => {
    if (!confirmTrips?.length) return ''
    let csvString = ''
    const { data } = getEconomicFeeKPI(confirmTrips, type, range, "customerAmount", dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildCostChart = (confirmTrips, type, range, dateStart, dateEnd) => {
    if (!confirmTrips?.length) return ''
    let csvString = ''
    const { data } = getEconomicFeeKPI(confirmTrips, type, range, "holderAmount", dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildTotalCostChart = (confirmTrips, type, range, dateStart, dateEnd) => {
    if (!confirmTrips?.length) return ''
    let csvString = ''
    const { data } = getEconomicFeeKPI(confirmTrips, type, range, "totalAmount", dateStart, dateEnd)
    data.forEach(elem => (csvString += `${elem.toString().replaceAll(',', CSV_SEPARATOR)}\r\n`))
    return csvString
}

const buildOriginDestinationChart = (groupedTrips) => {
    if (!groupedTrips?.length) return ''
    let csvString = ''

    const { header, body } = getOriginDestinationChart({ groupedTrips })

    csvString += `${header[0].name}${CSV_SEPARATOR}`

    header.slice(1).forEach((elem, i) => {
        csvString += `${elem.title}${i + 1 !== header.slice(1).length ? CSV_SEPARATOR : '\r\n'}`
    })

    body.forEach(elem => {
        const values = Object.values(elem)
        values.forEach((val, i) => {
            csvString += `${val}${i + 1 !== values.length ? CSV_SEPARATOR : '\r\n'}`
        })
    })

    return csvString
}

export const CsvBuilder = {
    summary: {
        buildAlertTable,
    },
    trips: {
        buildTripStateTable,
        buildTotalTripsChart,
        buildTimeSlotChart,
        buildPunctualityChart,
        buildAvgDistanceChart,
    },
    users: {
        buildTotalCustomersChart,
        buildTripsPerUser,
        buildTotalTripByUserChart,
    },
    reservations: {
        buildReservationTable,
        buildReservationTimeSlotsChat,
    },
    journeys: {
        buildTotalJourneysChart,
        buildTripsJourneysChart,
        buildNumberPassengersChart,
        buildJourneysDistanceChart,
        buildJourneysDurationChart,
    },
    driver: {
        buildTotalDriversChart,
        buildTimePerDriverChart,
        buildDriverDistanceChart,
        buildWheelJourneyDriversTable,
        buildDriversJourneyTable,
    },
    vehicle: {
        buildVehicleCapacityChart,
        buildVehicleDistanceChart,
    },
    economyData: {
        buildUserPayFeeChart,
        buildCostChart,
        buildTotalCostChart,
    },
    originDestination: {
        buildOriginDestinationChart,
    }
}