import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import { getTimeServiceByDriverChart } from "../../../helpers/kpiHelper";
import Chart from "react-google-charts";
import i18next from "i18next";

export default function TimePerDriversChart({ isLoading }) {
    const { t } = useTranslation()
    const driverCalendar = useSelector(state => state.kpiReducer.driverCalendar);
    const driverCalendarLoading = useSelector(state => state.kpiReducer.driverCalendarLoading)
    const weekDays = useSelector(state => state.serviceReducer.weekDays)

    const data = getTimeServiceByDriverChart({ driverCalendar, weekDays })
    isLoading = isLoading || driverCalendarLoading

    const options = {
        chart: {
            title: t("commandBox.drivers.timePerDriverChart.title"),
        },
        calendar: {
            daysOfWeek: 'LMMJVSD',
        },
        vAxis: { format: '0' }
    }

    return (
        <KpiWrapper
            title={t("commandBox.drivers.timePerDriverChart.title")}
            isLoading={isLoading}
            displayNoData={!data?.length}
            skeletonVariant="rect"
            skeletonHeight="840px"
        >
            <Chart
                graphID={'chart-driver'}
                chartType="Timeline"
                width="100%"
                height="800px"
                data={data}
                options={options}
                chartLanguage={i18next.language} />


        </KpiWrapper>
    )
}