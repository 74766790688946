import * as React from 'react'

export default function CsvSVG(props) {
    return (
        <svg viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg" fill="#000000" {...props}>
            <g id="xxx-word">
                <path
                    className="cls-1"
                    d="M325 105h-75a5 5 0 01-5-5V25a5 5 0 1110 0v70h70a5 5 0 010 10z"
                />
                <path
                    className="cls-1"
                    d="M325 154.83a5 5 0 01-5-5v-47.76L247.93 30H100a20 20 0 00-20 20v98.17a5 5 0 01-10 0V50a30 30 0 0130-30h150a5 5 0 013.54 1.46l75 75A5 5 0 01330 100v49.83a5 5 0 01-5 5zM300 380H100a30 30 0 01-30-30v-75a5 5 0 0110 0v75a20 20 0 0020 20h200a20 20 0 0020-20v-75a5 5 0 0110 0v75a30 30 0 01-30 30z"
                />
                <path
                    className="cls-1"
                    d="M275 280H125a5 5 0 110-10h150a5 5 0 010 10zm-75 50h-75a5 5 0 110-10h75a5 5 0 010 10z"
                />
                <path
                    className="cls-1"
                    d="M325 280H75a30 30 0 01-30-30v-76.83a30 30 0 0130-30h.2l250 1.66a30.09 30.09 0 0129.81 30V250A30 30 0 01325 280zM75 153.17a20 20 0 00-20 20V250a20 20 0 0020 20h250a20 20 0 0020-20v-75.17a20.06 20.06 0 00-19.88-20l-250-1.66z"
                />
                <path
                    className="cls-1"
                    d="M152.44 236h-34.65v-53.32h34.3v7.93H127.4v14.45h19.84v7.73H127.4v14.92h25zm37.74 0H180l-8.36-14.37-9.12 14.37h-7.66L168 215.69l-11.37-19.14h10.2l6.48 11.6 7.38-11.6h7.46L177 213.66zm27.22-14.49l7.66.78q-1.49 7.42-5.74 11a15.5 15.5 0 01-10.32 3.53q-8.17 0-12.56-6a23.89 23.89 0 01-4.39-14.59q0-8.91 4.8-14.73a15.77 15.77 0 0112.81-5.82q12.89 0 15.35 13.59l-7.66 1.05q-1-7.34-7.23-7.34a6.9 6.9 0 00-6.58 4 20.66 20.66 0 00-2.05 9.59q0 6 2.13 9.22a6.74 6.74 0 006 3.24q5.87-.03 7.78-7.52zm39.6 1.91l8 1.09a16.84 16.84 0 01-6.09 8.83 18.13 18.13 0 01-11.37 3.48q-8.2 0-13.2-5.51t-5-14.92q0-8.94 5-14.8t13.67-5.86q8.44 0 13 5.78t4.61 14.84v1h-27.01a22.12 22.12 0 00.76 6.45 8.68 8.68 0 003 4.22 8.83 8.83 0 005.66 1.8q6.64.01 8.97-6.4zm-.55-11.8a9.92 9.92 0 00-2.56-7 8.63 8.63 0 00-12.36-.18 11.36 11.36 0 00-2.89 7.13zM282.71 236h-8.91v-53.32h8.91z"
                />
            </g>
        </svg>
    )
}
