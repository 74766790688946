import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import TotalTripsByUserChart from "./components/totalTripsByUserChart";
import { TotalCustomersChart } from "./components/totalCustomersChart";
import { useKPIStyles } from "../../styles";
import { TripsPerUserChart } from "./components/tripsPerUserChart";

export default function UsersKPI() {
    const classes = useKPIStyles()

    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalCustomersChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TripsPerUserChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalTripsByUserChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
            </Grid>
        </section >
    )
}