import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

export default function IconButton({ onClickAction, text, disabled, children }) {
    const classes = useStyles()

    return (
        <button
            type="button"
            onClick={onClickAction}
            disabled={disabled}
            className={classes.button}
            aria-label={text}
        >
            <div className={classes.iconContainer}>{children}</div>
        </button>
    )
}

const useStyles = makeStyles({
    iconContainer: {
        width: '48px',
        height: '48px',
    },

    button: {
        padding: '5px',
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        transition: `transform .2s`,
        '&:hover': { transform: 'scale(1.1)' },
    },
})

IconButton.propTypes = {
    onClickAction: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
}
