import { Button, FormControl, Grid, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import MultiSelect from '../../shared/multiSelect'
import { useFilters } from '../hooks/useFilters'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Autocomplete } from '@material-ui/lab'
import { KPI_FILTER_OPTIONS } from '../../../constants/types'
import DownloadPagePDF from './downloadPagePDF'
import DownloadPageCSV from './downloadPageCSV'

export default function Filters({ currentKPI, ...props }) {
    const { t } = useTranslation()
    const [error, setError] = useState(false)

    const {
        dateStart,
        handleDateStartChange,
        dateEnd,
        handleDateEndChange,
        type,
        handleTypeChange,
        typeOptions,
        filterBy,
        handleFilterByChange,
        filterByOptions,
        range,
        handleRangeChange,
        onSubmit,
    } = useFilters({ currentKPI })

    const handleSubmit = e => {
        e.preventDefault()
        if (filterBy.length === 0) {
            setError(true)
            return
        }

        onSubmit({ dateStart, dateEnd, type, filterBy })
    }

    const handleFilter = e => {
        if (e.length > 0) {
            setError(false)
        }
        handleFilterByChange(e)
    }

    const rangeOptions = [
        { label: i18next.t('commandBox.year'), value: 'year' },
        { label: i18next.t('commandBox.month'), value: 'month' },
    ]

    const getFilterByLabel = type => {
        switch (type) {
            case KPI_FILTER_OPTIONS.services:
                return i18next.t('commandBox.services')
            case KPI_FILTER_OPTIONS.otps:
                return i18next.t('commandBox.otps')
            case KPI_FILTER_OPTIONS.tenant:
                return i18next.t('commandBox.tenant')
            case KPI_FILTER_OPTIONS.region:
                return i18next.t('commandBox.region')
            case KPI_FILTER_OPTIONS.municipalities:
                return i18next.t('commandBox.municipalities')
            default:
                return ''
        }
    }

    return (
        <form onSubmit={handleSubmit} {...props}>
            <Grid container spacing={2}>
                <Grid item sm={3} lg={1}>
                    <FormControl fullWidth>
                        <TextField
                            id="startDate"
                            label={t('commandBox.startDate')}
                            type="date"
                            variant="outlined"
                            value={dateStart.split('T')[0]}
                            InputProps={{
                                inputProps: {
                                    max: dateEnd.split('T')[0] || moment().format('YYYY-MM-DD'),
                                },
                            }}
                            onChange={e => handleDateStartChange(e.target.value)}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} lg={1}>
                    <FormControl fullWidth>
                        <TextField
                            id="finishDate"
                            label={t('commandBox.finishDate')}
                            type="date"
                            variant="outlined"
                            value={dateEnd.split('T')[0]}
                            InputProps={{
                                inputProps: {
                                    min: dateStart.split('T')[0] || moment().format('YYYY-MM-DD'),
                                    max: moment().format('YYYY-MM-DD'),
                                },
                            }}
                            onChange={e => handleDateEndChange(e.target.value)}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} lg={1}>
                    <Autocomplete
                        id="combo-box-range-field"
                        disableClearable
                        options={rangeOptions}
                        value={
                            rangeOptions.find(e => e.value === range) || { label: '', value: '' }
                        }
                        onChange={(_, value) => handleRangeChange(value.value)}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18next.t('commandBox.range')}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item sm={12} lg={2}>
                    <Autocomplete
                        id="combo-box-type-of-field"
                        disableClearable
                        options={typeOptions}
                        value={typeOptions.find(e => e.value === type) || { label: '', value: '' }}
                        onChange={(_, value) => handleTypeChange(value.value)}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18next.t('commandBox.type')}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item sm={12} lg={5}>
                    <MultiSelect
                        items={filterByOptions}
                        initValues={filterBy}
                        error={error}
                        label={getFilterByLabel(type)}
                        selectAllLabel={i18next.t('shared.multiSelect.selectAll')}
                        noOptionsText={t('shared.multiSelect.noOptions')}
                        onChange={handleFilter}
                    />
                </Grid>
                <Grid
                    item
                    sm={12}
                    lg={1}
                    style={{ display: 'flex', alignItems: 'center' }}
                    data-html2canvas-ignore
                >
                    <Button
                        style={{ height: '100%' }}
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {i18next.t('commandBox.filterButton')}
                    </Button>
                </Grid>
                <Grid
                    item
                    sm={12}
                    lg={1}
                    style={{ display: 'flex', alignItems: 'center' }}
                    data-html2canvas-ignore
                >
                    <DownloadPagePDF page={currentKPI} />
                    <DownloadPageCSV page={currentKPI} />
                </Grid>
            </Grid>
        </form>
    )
}
