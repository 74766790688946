import { KPIFiltersConstants } from "../../constants/kpiFilters_constant"

export const kpiFiltersActions = {
    setDateStart,
    setDateEnd,
    setRange,
    setType,
    setFilterBy,
}

function setDateStart(dateStart) {
    return { type: KPIFiltersConstants.SET_DATE_START, dateStart }
}

function setDateEnd(dateEnd) {
    return { type: KPIFiltersConstants.SET_DATE_END, dateEnd }
}

function setRange(range) {
    return { type: KPIFiltersConstants.SET_RANGE, range }
}

function setType(typeSelected) {
    return { type: KPIFiltersConstants.SET_TYPE, typeSelected }
}

function setFilterBy(filterBy) {
    return { type: KPIFiltersConstants.SET_FILTER_BY, filterBy }
}