import { makeStyles } from "@material-ui/core";

export const useKPIStyles = makeStyles({
    paper: {
        padding: "10px",
        minHeight: "250px"
    },

    paddingGraph: {
        padding: "10px 20px"
    },
    wrapper: {
        display: "grid",
        gap: "5px"
    }
})