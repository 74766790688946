import React from "react";
import { useSelector } from "react-redux";
import SimpleTable from "../../../components/simpleTable";
import { Skeleton } from "@material-ui/lab";
import AlertSVG from "../../../../../resources/svgs/alertSVG";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getAlertTable } from "../../../helpers/kpiHelper";

export default function AlertTable({ isLoading }) {
    const { t } = useTranslation()
    const alerts = useSelector(state => state.kpiReducer.alerts)
    const isAlertsLoading = useSelector(state => state.kpiReducer.alertsLoading)
    const { rows, total } = getAlertTable({ alerts })

    const theme = useTheme()

    isLoading = isAlertsLoading || isLoading

    return (
        <>
            {
                isLoading
                    ? <Skeleton variant="rect" height={"100%"} />
                    : <SimpleTable
                        icon={<AlertSVG width={40} height={30} stroke={theme.palette.primary.main} />}
                        title={t("commandBox.summary.alertTable.title")}
                        subtitle={t("commandBox.summary.alertTable.subtitle")}
                        rows={rows}
                        total={total} />
            }
        </>
    )

}