export function getMonthDifference(date1, date2) {
    date1 = new Date(date1)
    date2 = new Date(date2)

    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();

    return yearDiff * 12 + monthDiff
}

export function isDifferentYear(date1, date2) {
    date1 = new Date(date1)
    date2 = new Date(date2)

    return date2.getFullYear() !== date1.getFullYear();
}