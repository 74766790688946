import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { TotalDriversChart } from "./components/totalDriversChart";
import TimePerDriversChart from "./components/timePerDriverChart";
import DriversJourneyTable from "./components/driversJourneyTable";
import WheelJourneyDriversTable from "./components/wheelJourneyDriversTable";
import DriverDistanceChart from "./components/driverDistanceChart";
import { useKPIStyles } from "../../styles";

export default function DriversKPI() {
    const classes = useKPIStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalDriversChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TimePerDriversChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <DriverDistanceChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={4}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <WheelJourneyDriversTable isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={8}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <DriversJourneyTable isLoading={servicesLoading} />
                    </Paper>
                </Grid>
            </Grid>
        </section>
    )
}