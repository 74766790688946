import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import Chart from "react-google-charts";
import i18next from "i18next";
import { getUsersGroupBy } from "../../../helpers/kpiHelper";

export function TotalDriversChart({ isLoading }) {
    const { t } = useTranslation()

    const drivers = useSelector(state => state.kpiReducer.drivers);
    const isDriversLoading = useSelector(state => state.kpiReducer.driversLoading)
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer);

    const { data } = useMemo(() => {
        const { data } = getUsersGroupBy(drivers, type, range, dateStart, dateEnd);
        return { data };
    }, [drivers, type]);

    isLoading = isLoading || isDriversLoading
    const displayNoData = !isLoading && data?.length === 1

    return (
        <KpiWrapper
            title={t("commandBox.drivers.totalDriversChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ColumnChart"
                graphID={'chart_drivers'}
                data={data}
                chartLanguage={i18next.language} options={{
                    vAxis: { title: t("commandBox.drivers.totalDriversChart.label") },
                    isStacked: true,
                }}
            />
        </KpiWrapper>
    )
}