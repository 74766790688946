import { Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import OriginDestinationChart from "./components/originDestinationChart";
import { useKPIStyles } from "../../styles";

export default function OriginDestinationKPI() {
    const classes = useKPIStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)
    const type = useSelector(state => state.kpiFiltersReducer.type)

    return (
        <section className={classes.wrapper}>
            <Paper className={[classes.paper, classes.paddingGraph]} >
                <OriginDestinationChart isLoading={servicesLoading} groupedBy={type} />
            </Paper>
        </section>
    )
}