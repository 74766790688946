import * as XLSX from 'xlsx'
import { CSV_SEPARATOR } from '../constants/generics'

export function getXLSXBlob(dataList) {
    const workbook = XLSX.utils.book_new()

    dataList.forEach(({ title, data }) => {
        const worksheet = XLSX.utils.aoa_to_sheet(data.split('\n').map(row => {
            const arr = row.split(CSV_SEPARATOR)
            return arr.map((val) => isNaN(val) ? val : convertToNumber(val))
        }))

        XLSX.utils.book_append_sheet(workbook, worksheet, title)
    })

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    return blob
}

function convertToNumber(val) {
    if (val === '') return val
    return Number(val)
}