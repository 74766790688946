import React from "react";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { getPunctualityKPI } from "../../../helpers/kpiHelper";
import PropTypes from 'prop-types'

export default function PunctualityTripChart({ isLoading }) {
    const { t } = useTranslation();
    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type, range, dateStart, dateEnd, filterBy } = useSelector(state => state.kpiFiltersReducer)

    const isFilterByOne = filterBy?.length === 1

    const { data } = getPunctualityKPI(confirmTrips, type, range, dateStart, dateEnd, isFilterByOne)


    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && data?.length === 0

    return (
        <KpiWrapper
            title={t("commandBox.trips.punctualityTripsChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_punctuality_trips'}
                data={data}
                options={{
                    seriesType: "bars",
                    isStacked: true,
                }}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}

PunctualityTripChart.propTypes = {
    isLoading: PropTypes.bool
}