import React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { TotalCustomersChart } from "../users/components/totalCustomersChart";
import TimeSlotsChart from "../trips/components/timeSlotsChart";
import AlertTable from "./components/alertTable";
import TripStateTable from "../trips/components/tripStateTable";
import ReservationTable from "../reservations/components/reservationTable";
import TotalTripsChart from "../trips/components/totalTripsChart";
import { useKPIStyles } from "../../styles";

export default function SummaryKPI() {
    const bentoClass = useStyles()
    const classes = useKPIStyles()

    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={bentoClass.bentoGrid}>
            <Paper className={classes.paper} style={{ gridArea: "TripStateTable" }}>
                <TripStateTable isLoading={servicesLoading} />
            </Paper>
            <Paper className={[classes.paper, classes.paddingGraph]} style={{ gridArea: "TotalTripsChart" }}>
                <TotalTripsChart isLoading={servicesLoading} />
            </Paper>
            <Paper className={classes.paper} style={{ gridArea: "AlertTable" }}>
                <AlertTable isLoading={servicesLoading} />
            </Paper>
            <Paper className={[classes.paper, classes.paddingGraph]} style={{ gridArea: "Customers" }}>
                <TotalCustomersChart isLoading={servicesLoading} />
            </Paper>
            <Paper className={[classes.paper, classes.paddingGraph]} style={{ gridArea: "ReservationTable" }}>
                <ReservationTable isLoading={servicesLoading} />
            </Paper>
            <Paper className={[classes.paper, classes.paddingGraph]} style={{ gridArea: "TimeSlotsChart" }}>
                <TimeSlotsChart isLoading={servicesLoading} />
            </Paper>
        </section>
    )
}

const useStyles = makeStyles({
    bentoGrid: {
        width: "100%", gap: "5px", display: "grid", gridTemplateColumns: "repeat(6,1fr)", gridTemplateRows: "auto",
        gridTemplateAreas: `
            "TripStateTable TotalTripsChart TotalTripsChart TotalTripsChart TotalTripsChart TotalTripsChart"
            "AlertTable Customers Customers Customers Customers Customers"
            "AlertTable ReservationTable ReservationTable ReservationTable ReservationTable ReservationTable"
            "TimeSlotsChart TimeSlotsChart TimeSlotsChart TimeSlotsChart TimeSlotsChart TimeSlotsChart"`
    },
})