import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { getEconomicFeeKPI } from "../../../helpers/kpiHelper";
import KpiWrapper from "../../../components/kpiWrapper";
import PropTypes from 'prop-types'
export default function UserPayFeeChart({ isLoading }) {
    const { t } = useTranslation()

    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)

    const { data, total } = getEconomicFeeKPI(confirmTrips, type, range, "customerAmount", dateStart, dateEnd)

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    const options = {
        width: '100%',
        seriesType: "bars",
        isStacked: true,
    }

    return (
        <KpiWrapper
            title={t("commandBox.economyData.userPayFeeChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${total?.toFixed(2).toLocaleString()}€`}
            skeletonVariant="rect"
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_pay_fee_user'}
                data={data}
                chartLanguage={i18next.language}
                options={options}
            />
        </KpiWrapper>
    )
}

UserPayFeeChart.propTypes = {
    isLoading: PropTypes.bool
}