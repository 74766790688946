import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { getJourneyPassengers } from "../../../helpers/kpiHelper";
import KpiWrapper from "../../../components/kpiWrapper";

export default function NumberPassengersChart({ isLoading }) {
    const { t } = useTranslation()

    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer)

    const { data, total } = useMemo(() => {
        return getJourneyPassengers(journeys, type, range, dateStart, dateEnd)
    }, [journeys, type, range])

    isLoading = isLoading || isJourneysLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    const options = {
        width: '100%',
        seriesType: "bars",
        isStacked: true,
    }

    return (
        <KpiWrapper
            title={t("commandBox.journeys.numberPassengersChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect"
            total={total?.toFixed(2)}
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_passengers_journeys'}
                data={data}
                options={options}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}