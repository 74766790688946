import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { getJourneyKmsKPI } from "../../../helpers/kpiHelper";
import KpiWrapper from "../../../components/kpiWrapper";

export default function JourneysDistanceChart({ isLoading }) {
    const { t } = useTranslation()

    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer)

    const { data, total } = useMemo(() => {
        if (!journeys.length) return { data: [] }

        return getJourneyKmsKPI(journeys, type, range, dateStart, dateEnd)
    }, [journeys, type])

    isLoading = isLoading || isJourneysLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    const options = {
        width: '100%',
        seriesType: "bars",
        isStacked: true,
    }

    return (
        <KpiWrapper
            title={t("commandBox.journeys.journeysDistanceChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${total?.toFixed(2)} km`}
            skeletonVariant="rect"
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_kms_journeys'}
                data={data}
                chartLanguage={i18next.language}
                options={options}
            />
        </KpiWrapper>
    )
}