import { useDispatch, useSelector } from "react-redux"
import { kpiFiltersActions } from "../../../redux/actions/kpiFilters_action"
import { useEffect, useState } from "react"
import moment from "moment"
import { KPIS_PAGES, KPI_FILTER_OPTIONS, DRIVER_REQUEST_STATUS } from "../../../constants/types"
import { kpiActions } from "../../../redux/actions/kpi_action"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

// TODO refactor
export function useFilters({ currentKPI }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [filterByOptions, setFilterByOptions] = useState([])
    const { dateStart: defaultDateStart, dateEnd: defaultDateEnd, type: defaultType, filterBy: defaultFilterBy } = useSelector(state => state.kpiFiltersReducer);

    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [range, setRange] = useState('month')
    const [type, setType] = useState(KPI_FILTER_OPTIONS.services)
    const [filterBy, setFilterBy] = useState([])

    const { serviceList } = useSelector(state => state.serviceReducer)
    const otps = useSelector(state => state.otpReducer.results)
    const tenants = useSelector(state => state.tenantReducer.results)
    const regions = useSelector(state => state.areaReducer.regions)
    const municipalities = useSelector(state => state.areaReducer.municipalities)

    const dispatchDataForKPI = ({ dateStart, dateEnd, filterBy }) => {
        const commonPayload = { type, data: filterBy };

        switch (currentKPI) {
            case KPIS_PAGES.summary:
                dispatch(kpiActions.getCustomersKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getReservationsKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getAlertsKPI(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.journey:
                dispatch(kpiActions.getJourneysKPI(dateStart, dateEnd, commonPayload, i18next.language));
                break;
            case KPIS_PAGES.reservations:
                dispatch(kpiActions.getReservationsKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getTripBookingKPI(dateStart, dateEnd, commonPayload, i18next.language))
                break;
            case KPIS_PAGES.trips:
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.users:
                dispatch(kpiActions.getCustomersKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.drivers:
                dispatch(kpiActions.getDriversKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getDriverRequestsKPI(dateStart, dateEnd, commonPayload, [DRIVER_REQUEST_STATUS.New, DRIVER_REQUEST_STATUS.Accepted], i18next.language));
                dispatch(kpiActions.getJourneysKPI(dateStart, dateEnd, commonPayload, i18next.language));
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getDriverCalendarKPI(dateStart, dateEnd, commonPayload, i18next.language));
                break;
            case KPIS_PAGES.vehicles:
                dispatch(kpiActions.getJourneysKPI(dateStart, dateEnd, commonPayload, i18next.language));
                dispatch(kpiActions.getVehicleGeneralDataKPI(dateStart, dateEnd, commonPayload, i18next.language));
                break;
            case KPIS_PAGES.economyData:
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.originDestination:
                dispatch(kpiActions.getConfirmedGroupedTripsKPI(dateStart, dateEnd, commonPayload, i18next.language));
                break;
            default:
                break;
        }
    };

    const onSubmit = async ({ dateStart, dateEnd, type, filterBy }) => {
        dispatch(kpiFiltersActions.setDateStart(dateStart))
        dispatch(kpiFiltersActions.setDateEnd(dateEnd))
        dispatch(kpiFiltersActions.setRange(range))
        dispatch(kpiFiltersActions.setType(type))
        dispatch(kpiFiltersActions.setFilterBy(filterBy))
        dispatchDataForKPI({ dateStart, dateEnd, filterBy })
    }

    const handleDateStartChange = (date) => {
        setDateStart(date)
    }

    const handleDateEndChange = (date) => {
        setDateEnd(date)
    }

    const handleTypeChange = (val) => {
        setFilterBy([])
        setType(val)
    }

    const handleRangeChange = (val) => {
        setRange(val)
    }

    const handleFilterByChange = (val) => {
        setFilterBy(val)
    }

    useEffect(() => {
        // set default form values
        defaultDateStart && setDateStart(defaultDateStart)
        defaultDateEnd && setDateEnd(defaultDateEnd)
        defaultType && setType(defaultType)
        defaultFilterBy && setFilterBy(defaultFilterBy)
    }, [defaultDateStart, defaultDateEnd, defaultType, defaultFilterBy])

    useEffect(() => {
        let filterByData = filterBy;

        if (filterByData?.length > 0) {
            dispatchDataForKPI({ dateStart, dateEnd, filterBy: filterByData });
        }

    }, [currentKPI])


    useEffect(() => {
        // init filters by default with services

        if (serviceList.length > 0 && filterBy.length === 0) {
            const res = dispatch(kpiFiltersActions.setFilterBy(serviceList.map(e => ({ label: e.name, value: e.id }))))
            dispatchDataForKPI({ dateStart, dateEnd, filterBy: res.filterBy });
        }
    }, [serviceList])

    useEffect(() => {
        const optionsMap = {
            [KPI_FILTER_OPTIONS.services]: serviceList,
            [KPI_FILTER_OPTIONS.otps]: otps,
            [KPI_FILTER_OPTIONS.tenant]: tenants,
            [KPI_FILTER_OPTIONS.region]: regions,
            [KPI_FILTER_OPTIONS.municipalities]: municipalities,
        };

        const options = optionsMap[type];
        if (options) {
            setFilterByOptions(options.map(e => ({ label: e.name, value: e.id })));
        }
    }, [type, serviceList, otps, tenants, regions, municipalities]);

    const typeOptions = [
        { label: t('commandBox.services'), value: KPI_FILTER_OPTIONS.services },
        { label: t('commandBox.otps'), value: KPI_FILTER_OPTIONS.otps },
        { label: t('commandBox.tenant'), value: KPI_FILTER_OPTIONS.tenant },
        { label: t('commandBox.region'), value: KPI_FILTER_OPTIONS.region },
        { label: t('commandBox.municipalities'), value: KPI_FILTER_OPTIONS.municipalities },
    ]

    return {
        dateStart: dateStart ? moment(dateStart, 'YYYY-MM-DD').utcOffset(0, true).toDate().toISOString() : '', handleDateStartChange,
        dateEnd: dateEnd ? moment(dateEnd, 'YYYY-MM-DD').utcOffset(0, true).toDate().toISOString() : '', handleDateEndChange,
        type, handleTypeChange,
        typeOptions,
        filterBy, handleFilterByChange,
        filterByOptions,
        onSubmit,
        range,
        handleRangeChange,
    }
}
