import React from 'react'
import PdfCsv from '../../../resources/svgs/pdf'
import IconButton from './iconButton'
import useKpiLoading from '../hooks/useKpiLoading'
import i18next from 'i18next'
import PropTypes from 'prop-types'
export default function DownloadPagePDF({ page }) {
    const { isLoading } = useKpiLoading()

    const onClickAction = async () => {
        const html2pdf = await require('html2pdf.js')
        const pageHTML = document.querySelector('.kpiPage')
        const title = i18next.t(`commandBox.${page}.title`)

        const opt = {
            margin: [5, 0.5],
            filename: `${title}_kpi`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 2,
                letterRendering: true,
                useCORS: true,
            },
            compress: true,
            jsPDF: {
                unit: 'mm',
                format: [275, 500],
                orientation: 'landscape',
                compress: true,
            },
        }

        html2pdf(pageHTML, opt)
    }

    return (
        <IconButton text="Descargar Pdf" disabled={isLoading} onClickAction={onClickAction}>
            <PdfCsv />
        </IconButton>
    )
}

DownloadPagePDF.propTypes = {
    page: PropTypes.string,
}
