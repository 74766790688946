import React from "react";
import { Paper } from "@material-ui/core";
import Filters from "./components/filters";
import Dashboard from "../dashboard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import KPINavBar from "./components/navBar";
import { useInitialLoad } from "./hooks/useInitialKpiLoad";
import PropTypes from 'prop-types'

export default function KpiLayout({ children }) {
    return (
        <Dashboard
            isBackgroundTransparent={true}
            component={<Layout>{children}</Layout>}
        />
    )
}

KpiLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

const Layout = ({ children }) => {
    useInitialLoad()
    const location = useLocation()
    const currentKPI = location.pathname.split("/")[2]


    return (
        <div className="kpiPage" style={{ display: "grid", gap: "10px", width: "100%" }}>
            <Paper style={{ display: "grid", gap: "5px", }}>
                <Filters currentKPI={currentKPI || "summary"} style={{ padding: "20px" }} />
                <KPINavBar currentKPIPage={currentKPI} />
            </Paper>
            {children}
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}