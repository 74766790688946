import { useSelector } from "react-redux";

export default function useKpiLoading() {

    const {
        confirmTripsLoading,
        reservationsLoading,
        alertsLoading,
        customersLoading,
        customersPerServiceLoading,
        driversLoading,
        driverRequestLoading,
        journeysLoading,
        confirmGroupedTripsLoading,
        driverCalendarLoading,
        tripBookingLoading,
        vehicleGeneralDataLoading
    } = useSelector(state => state.kpiReducer);

    return (
        {
            isLoading: confirmTripsLoading ||
                reservationsLoading ||
                alertsLoading ||
                customersLoading ||
                customersPerServiceLoading ||
                driversLoading ||
                driverRequestLoading ||
                journeysLoading ||
                confirmGroupedTripsLoading ||
                driverCalendarLoading ||
                tripBookingLoading ||
                vehicleGeneralDataLoading
        }
    );
}