import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PunctualityTripChart from "./components/punctualityTripsChart";
import TimeSlotsChart from "./components/timeSlotsChart";
import AvgDistanceChart from "./components/avgDistanceChart";
import TotalTripsChart from "./components/totalTripsChart";
import { useKPIStyles } from "../../styles";

export default function TripsKPI() {
    const classes = useKPIStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)
    const type = useSelector(state => state.kpiFiltersReducer.type)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalTripsChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <PunctualityTripChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TimeSlotsChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <AvgDistanceChart isLoading={servicesLoading} groupedBy={type} />
                    </Paper>
                </Grid>
            </Grid>
        </section>
    )
}