import React from "react";
import { getOriginDestinationChart } from "../../../helpers/kpiHelper";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";

export default function OriginDestinationChart({ isLoading }) {
    const { t } = useTranslation()
    const groupedTrips = useSelector(state => state.kpiReducer.confirmGroupedTrips)
    const isGroupedTripsLoading = useSelector(state => state.kpiReducer.confirmGroupedTripsLoading)

    const { header, body } = getOriginDestinationChart({ groupedTrips })

    isLoading = isLoading || isGroupedTripsLoading
    const displayNoData = !isLoading && !body || body?.length === 0

    return (
        <KpiWrapper
            title={t("commandBox.originDestination.title")}
            isLoading={isLoading}
            skeletonVariant="rect"
            skeletonHeight="800px"
            displayNoData={displayNoData}
        >
            <MaterialTable
                options={{
                    sorting: true,
                    search: false,
                    exportButton: false,
                    toolbar: false,
                    paging: false
                }}
                columns={header}
                data={body}
            >
            </MaterialTable>
        </KpiWrapper>
    )
}