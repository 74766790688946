import React from "react";

export default function AlertSVG({ height = 44, width = 44, stroke = "#000" }) {
    return (
        <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
            <g clipPath="url(#a)" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M14.667 29.333V22a7.334 7.334 0 0 1 14.666 0v7.333M5.5 22h1.833M22 5.5v1.833M36.667 22H38.5M10.267 10.267l1.283 1.283m22.183-1.283L32.45 11.55M11 31.167a1.833 1.833 0 0 1 1.833-1.834h18.334A1.833 1.833 0 0 1 33 31.168v3.666a1.834 1.834 0 0 1-1.833 1.834H12.833A1.833 1.833 0 0 1 11 34.833v-3.666Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h44v44H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}


