import React from 'react'
import { Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'
import { history } from '../../../helpers/history'
import { useTranslation } from 'react-i18next'

export default function KPINavBar({ currentKPIPage = '' }) {
    const { t } = useTranslation()

    return (
        <nav data-html2canvas-ignore>
            <Tabs
                value={currentKPIPage}
                onChange={(_, newValue) => {
                    history.push(`/commandBox/${newValue}`)
                }}
                aria-label="navegación kpis"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant="scrollable"
            >
                <Tab label={t('commandBox.summary.title')} value="" />
                <Tab label={t('commandBox.trips.title')} value="trips" />
                <Tab label={t('commandBox.journeys.title')} value="journeys" />
                <Tab label={t('commandBox.reservations.title')} value="reservations" />
                <Tab label={t('commandBox.users.title')} value="users" />
                <Tab label={t('commandBox.drivers.title')} value="drivers" />
                <Tab label={t('commandBox.vehicles.title')} value="vehicles" />
                <Tab label={t('commandBox.economyData.title')} value="economyData" />
                <Tab label={t('commandBox.originDestination.title')} value="originDestination" />
            </Tabs>
        </nav>
    )
}

KPINavBar.propTypes = {
    currentKPIPage: PropTypes.string.isRequired,
}
