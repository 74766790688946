import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import KpiWrapper from "../../../components/kpiWrapper";
import PropTypes from 'prop-types'
import { getTripsPerUsers } from "../../../helpers/kpiHelper";

export function TripsPerUserChart({ isLoading }) {
    const { t } = useTranslation();

    const trips = useSelector(state => state.kpiReducer.confirmTrips);
    const customers = useSelector(state => state.kpiReducer.customers);
    const isConfirmTripLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const isCustomersLoading = useSelector(state => state.kpiReducer.customersLoading);

    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer);

    const { data, total } = useMemo(() => {
        if (isConfirmTripLoading || isCustomersLoading) return { data: [] }
        const { data, total } = getTripsPerUsers(trips, customers, type, range, dateStart, dateEnd);
        return { data, total };
    }, [trips, type, isConfirmTripLoading, isCustomersLoading]);

    isLoading = isLoading || isConfirmTripLoading || isCustomersLoading;
    const displayNoData = !isLoading && data.length === 0;

    return (
        <KpiWrapper
            title={t("commandBox.users.tripsPerUser.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${total?.toFixed(2)}`}
            skeletonVariant="rect">
            <Chart
                chartType="ColumnChart"
                graphID={'chart_trips_customers'}
                data={data}
                width={"100%"}
                chartLanguage={i18next.language}
                options={{
                    vAxis: { title: t("commandBox.users.customersChart.vAxis") },
                    isStacked: true,
                }}
            />
        </KpiWrapper>
    );
}

TripsPerUserChart.propTypes = {
    isLoading: PropTypes.bool
}