import { Typography, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function KpiWrapper({ children, displayNoData = false, title, total, isLoading, skeletonVariant = "react", skeletonHeight = "250px" }) {
    const { t } = useTranslation()
    const classes = useStyles()

    // remove html tooltips from chart components (Memory leak)
    useEffect(() => {
        return () => {
            const arr = document.querySelectorAll("[style*='position: absolute; display: none;']")
            arr.forEach((elem) => {
                elem.remove()
            })
        }
    }, [children])

    return (
        <>
            {
                isLoading ? (
                    <>
                        <Skeleton variant={skeletonVariant} style={{ minHeight: skeletonHeight }} />
                        {/** Por algún motivo no se carga bien el gráfico una vez se han cargado los datos, 
                         * por eso se renderiza oculto también durante la carga  */}
                        <div className={classes.hiddenContainer}>
                            {children}
                        </div>
                    </>
                ) : (
                    <div className={`${classes.minHeight} kpiWrapper`}>
                        <div className={classes.headerContainer}>
                            {title && <Typography variant={"h6"} style={{ fontWeight: "600" }} >{title}</Typography>}
                            {!displayNoData && total && <div className={classes.totalContainer}>
                                <Typography variant={"h6"}>{t("commandBox.summary.kpiWrapper.total")} </Typography>
                                {<Typography variant={"h5"} color='primary' style={{ fontWeight: "600" }}>{total}</Typography>}
                            </div>}
                        </div>
                        {
                            displayNoData ? (
                                <div className={classes.noDataContainer}>
                                    <Typography variant={"h6"}>{t("commandBox.summary.kpiWrapper.noData")}</Typography>
                                </div>
                            ) : (
                                children
                            )
                        }
                    </div >
                )
            }
        </>
    )

}

KpiWrapper.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    total: PropTypes.string || PropTypes.number,
    isLoading: PropTypes.bool,
    skeletonVariant: PropTypes.string,
}

const useStyles = makeStyles({
    noDataContainer: {
        minHeight: "250px",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
        justifyContent: "center"
    },
    hiddenContainer: {
        visibility: 'hidden',
        position: 'absolute',
        opacity: '0'
    },
    minHeight: {
        minHeight: "250px"
    },
    headerContainer: {
        display: "flex",
        gap: "2px",
        marginBottom: "10px",
        justifyContent: "space-between"
    },
    totalContainer: {
        display: "flex",
        gap: "4px",
        alignItems: "baseline"
    }


})
export default KpiWrapper