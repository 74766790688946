import { useDispatch } from "react-redux"
import { serviceActions } from "../../../redux/actions/services_actions"
import { otpActions } from "../../../redux/actions/otp_actions"
import { tenantsActions } from "../../../redux/actions/tenant_actions"
import { areaActions } from "../../../redux/actions/areas_actions"
import { useEffect } from "react"

export function useInitialLoad() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(serviceActions.getServiceList())
        dispatch(otpActions.getAll())
        dispatch(tenantsActions.getAll())
        dispatch(areaActions.getAllRegions())
        dispatch(areaActions.getAllMunicipalities())
        dispatch(serviceActions.getWeekDays())
    }, [dispatch])
}