import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import { getTotalTripsByUserChart } from "../../../helpers/kpiHelper";
import MaterialTable from 'material-table';
import PropTypes from 'prop-types'

export default function TotalTripsByUserChart({ isLoading }) {
    const { t } = useTranslation()
    const trips = useSelector(state => state.kpiReducer.confirmTrips);
    const isConfirmTripLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)

    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { header, body, total } = getTotalTripsByUserChart({ trips, type, order: true })


    isLoading = isLoading || isConfirmTripLoading
    const displayNoData = !isLoading && Number(total) === 0

    return (
        <KpiWrapper
            title={t("commandBox.users.totalTripsByUserChart.title")}
            isLoading={isLoading}
            total={total}
            skeletonVariant="rect"
            skeletonHeight="500px"
            displayNoData={displayNoData}
        >
            <MaterialTable
                options={{
                    sorting: true,
                    search: false,
                    exportButton: false,
                    toolbar: false,
                    paging: false
                }}
                columns={header}
                data={body}
            >
            </MaterialTable>
        </KpiWrapper >
    )
}

TotalTripsByUserChart.propTypes = {
    isLoading: PropTypes.bool
}